import { getUserIsAdmin, isAuthenticated } from '@/utils/permissions/helpers';
import { PermissionFn } from '@/utils/permissions/types';

interface AccountPermissions {
  canRead: PermissionFn;
}
const isAdmin: PermissionFn = ({ session }) => {
  if (!isAuthenticated({ session })) return false;

  return getUserIsAdmin(session?.user);
};

export const accountPermissions: AccountPermissions = {
  canRead: isAdmin,
};
