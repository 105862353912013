'use client';

import { AnimatePresence, motion } from 'framer-motion';
import { AlertOctagon, CheckCircle2, Info, XOctagon } from 'lucide-react';
import { isValidElement, useState } from 'react';

import { Heading } from '@/components/heading';
import { Icon } from '@/components/icon';
import { Text } from '@/components/text';
import { VisuallyHidden } from '@/components/visually-hidden';
import { tv } from '@/utils/styles';

export interface UserMessageProps {
  heading?: string;
  body?: string | React.ReactNode;
  variant?: 'success' | 'error' | 'warning' | 'info';
  icon?: string;
  noIcon?: boolean;
  className?: string;
  size?: 'sm';
  onClose?: () => void;
}

const UserMessage = ({
  heading,
  body,
  variant = 'info',
  icon,
  noIcon,
  className,
  size,
  onClose,
  ...props
}: UserMessageProps) => {
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    if (onClose) {
      onClose();
      setIsClosed(true);
    }
  };

  const getIcon = () => {
    const iconConfig = {
      info: Info,
      success: CheckCircle2,
      warning: AlertOctagon,
      error: XOctagon,
    };

    return icon || iconConfig[variant];
  };

  if (!heading && !body) {
    return null;
  }

  const {
    base,
    inner,
    iconStyles,
    iconWrapper,
    verticalBand,
    headingStyles,
    content,
    contentInner,
    contentBody,
    closeButton,
  } = styles({
    variant,
    noIcon,
    size,
    isDismissable: !!onClose,
  });

  const IconElement = getIcon();

  return (
    <AnimatePresence>
      {!isClosed && (
        <motion.aside
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
          className={base({ className })}
          {...props}
        >
          <div className={inner()}>
            <span className={verticalBand()} />
            {!noIcon && (
              <div className={iconWrapper()}>
                <IconElement className={iconStyles()} />
              </div>
            )}
            <div className={content()}>
              <div className={contentInner()}>
                {heading && (
                  <Heading variant={size === 'sm' ? 'h5' : 'h4'} as="h2" className={headingStyles()}>
                    {heading}
                  </Heading>
                )}

                {body && (
                  <div className={contentBody()}>
                    {body && Array.isArray(body) && body.every(isValidElement) ? (
                      body
                    ) : (
                      <Text variant="secondary" className="leading-normal" dangerouslySetInnerHTML={{ __html: body }} />
                    )}
                  </div>
                )}
              </div>
            </div>
            {Boolean(onClose) && (
              <button onClick={handleClose} type="button" className={closeButton()}>
                <VisuallyHidden>Dismiss</VisuallyHidden>
                <Icon name="close" />
              </button>
            )}
          </div>
        </motion.aside>
      )}
    </AnimatePresence>
  );
};

const styles = tv({
  slots: {
    base: '[&_a]:text-inherit [&_a]:hover:text-decoration-none mx-auto my-8 ml-auto max-w-xl rounded xl:my-12',
    closeButton: 'absolute right-2.5 top-2.5 size-6 [&_svg]:!transition-all hover:[&_svg]:fill-grey-800',
    content: 'ml-2 flex items-center [&_a]:underline [&_a]:hover:no-underline',
    contentBody: 'leading-normal text-grey-800',
    contentInner: null,
    headingStyles: 'mb-0.5 text-[inherit]',
    iconStyles: 'ml-2 size-6',
    iconWrapper: 'flex shrink-0 justify-center',
    inner: 'relative mx-auto flex p-4 pl-4 pr-6 [&_*:last-child]:mb-0',
    verticalBand: 'absolute bottom-4 left-4 right-0 top-4 block w-0.5 rounded-lg',
  },
  variants: {
    variant: {
      info: {
        base: 'bg-lightBlue-100 text-lightBlue-600',
        closeButton: '[&_svg]:fill-lightBlue-600',
        verticalBand: 'bg-lightBlue-600',
      },
      success: {
        base: 'bg-states-success-backgroundColor text-states-success-color [&_svg]:text-states-success-color',
        closeButton: '[&_svg]:fill-states-success-color',
        verticalBand: 'bg-states-success-color',
      },
      warning: {
        base: 'bg-states-warning-backgroundColor text-states-warning-color [&_svg]:text-states-warning-color',
        closeButton: '[&_svg]:fill-states-warning-color',
        verticalBand: 'bg-states-warning-color',
      },
      error: {
        base: 'bg-states-error-backgroundColor text-error [&_svg]:text-error',
        closeButton: '[&_svg]:fill-error',
        verticalBand: 'bg-error',
      },
    },
    size: {
      sm: {
        base: 'min-h-none xl:my-3 [&_p]:text-sm',
        closeButton: 'right-1.5 top-1.5 size-6',
        content: 'text-base text-[inherit]',
        contentBody: 'text-[inherit]',
        iconStyles: 'size-5',
        iconWrapper: 'ml-1',
        inner: 'p-2.5',
        verticalBand: 'bottom-2.5 left-2.5 top-2.5',
      },
    },
    noIcon: {
      true: {
        content: 'pl-2',
        headingStyles: 'ml-0 pl-0',
      },
    },
    isDismissable: {
      true: {
        inner: 'pr-12',
      },
    },
  },
  compoundVariants: [
    {
      size: 'sm',
      noIcon: true,
      class: {
        content: 'ml-0 pl-3',
      },
    },
  ],
});

export { UserMessage };
